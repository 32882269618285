import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useGroups() {
    
    const toast = useToast()
    const refGroupListTable = ref(null);
    const tableColumns = [
      
        { key: 'title', label: 'Group', sortable: false },
        { key: 'action', label: '#', sortable: false },


    ]
    const perPage = ref(10)
    const totalGroups = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refGroupListTable.value ? refGroupListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalGroups.value,
        }
    })
    const refetchData = () => {
        refGroupListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })
    const fetchGroups = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
        console.log('offset',offset);
        store
          .dispatch('permissions-groups/fetchGroups', {
            limit: perPage.value,
            offset:offset
           /* q: searchQuery.value,
            
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  groups  = response.data.data
           
            const total = response.data.total;
            callback(groups)
            totalGroups.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching invoices' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    return {
        fetchGroups,
        tableColumns,
        perPage,
        currentPage,
        totalGroups,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refGroupListTable,
    
        statusFilter,
    
   
    
        refetchData,
     }
}